@tailwind base;
@tailwind components;
@tailwind utilities;
/* In your main CSS or App.css file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "lexend-light";
  src: url("./assets/fonts/Lexend-Light.ttf");
}

@font-face {
  font-family: "lexend-Regular";
  src: url("./assets/fonts/Lexend-Regular.ttf");
}

@font-face {
  font-family: "lexend-Bold";
  src: url("./assets/fonts/Lexend-Bold.ttf");
}

@font-face {
  font-family: "lexend-SemiBold";
  src: url("./assets/fonts/Lexend-Thin.ttf");
}

@font-face {
  font-family: "lexend-Medium";
  src: url("./assets/fonts/Lexend-Medium.ttf");
}

.home-page {
  margin: 0;
  background-image: url(/src/assets/images/BgImage.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-family: "Inter", sans-serif !important;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

body.modal-open {
  overflow: hidden;
  height: auto;
  position: fixed;
  width: 100%;
}

.blur-effect {
  filter: blur(5px);
  pointer-events: none;
}

@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.2);
  }
}

html {
  scroll-behavior: smooth;
}

.invisible-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.invisible-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#terms {
  accent-color: black;
}

.custom-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='%230F172AFF' stroke-width='3' stroke-dasharray='10%2c 20' stroke-dashoffset='5' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 18px;
}
